/**
 * @example
 *
 * const store  = createStore({ foo: true });
 * store.subscribe((previousState, nextState) => {
 *   if (previousState.current.foo !== nextState.current.foo) {
 *     console.log('foo changed !');
 *   }
 * });
 *
 * @param {*} initialState
 */
function createStore(initialState) {
  /**
   * Etat de l'app (toutes les données)
   */
  let currentState = initialState;

  /**
   * Tableau de callback ({ current: previousState }, { current: nextState }) => {}
   */
  const subscriptions = [];

  function setState(newValues) {
    const previousState = currentState;
    const nextState = { ...currentState, ...newValues };
    currentState = nextState;

    // Notifie toutes les subscriptions
    subscriptions.forEach((subscription) => {
      // On utilise la meme signature { current } pour pouvoir réutiliser les sélecteurs
      subscription(Object.freeze({ current: previousState }), Object.freeze({ current: nextState }));
    });
  }

  function subscribe(fn) {
    subscriptions.push(fn);
  }

  function unsubscribe(fn) {
    subscriptions.splice(subscriptions.indexOf(fn), 1);
  }

  return {
    get current() {
      return currentState;
    },
    set current(value) {
      currentState = value;
    },
    setState,
    subscribe,
    unsubscribe,
  };
}

export const applicationStore = createStore({
  loading: {
    'job:OrdersDownloadJob': false,
    'job:draftOrdersDownload': false,
  },
  ...window.__APPLICATION_STATE__,
});
